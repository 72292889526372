import { mapActions, mapState } from 'vuex'
import { app } from '@/config'

export default {
  name: 'Header',
  computed: {
    ...mapState('user', ['isLoggedIn', 'currentUser']),
    version () {
      return app.version
    }
  },
  methods: {
    ...mapActions('user', ['logOut']),
    doLogOut () {
      this.logOut()
      this.$router.push('/login')
    }
  }
}
